<template>

    <div>



    <b-modal
      ref="modalEnviarRemesas"
      centered
      hide-footer
      @hidden="closeModalEnviarRemesas"
      size="sm"
      :title="title"
    >
      <div class="d-block text-center">




        <b-form
          autocomplete="off"
          @submit.prevent="onSubmit"
        >


          <b-row>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <b-card>

              <b-row>

                <b-col cols="3" sm="3" md="3" lg="2" xl="2" style="display:flex; padding:0px">

                  <b-avatar
                    size="45"
                    variant="light-primary"
                    style="margin:auto"
                    >

                    <span class="d-flex align-items-center">


                      <feather-icon
                    icon="UserIcon"
                    size="21"
                    class="color_icon"
                  />

                    </span>

                    </b-avatar>

                  </b-col>


                  <b-col cols="9" sm="9" md="9" lg="10" xl="10">

                    <p style="font-size: 14px; margin-bottom:0px; text-align: left; font-weight: bold;">{{firstName}} {{lastName}}</p>
                    <p style="font-size: 10px; margin-bottom:0px; text-align: left; ">{{nameBank}}</p>
                    </b-col>

              </b-row>
        
                  
                   
                  </b-card>
                    </b-col>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12">

    

              <b-alert
                variant="secondary"
                show
                style="margin-bottom: 10px !important;"
              >
                <div class="alert-body">
                  
                <p class="saldoDisponible"><span style="font-size:25px">$</span>{{balance}}<small class="currency_cripto">USD</small></p>
                <p style="font-size: 12px;">Saldo disponible para envio</p>

                
                </div>
              </b-alert>
           
              </b-col>
          
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
              
              <p
                                        class="textLabel"
                                        style="text-align: center;margin-top: 0px;margin-bottom: 5px;"
                                      >
                                        ¿Cuánto vas a enviar? (*):</p>

                                        <AutoNumericVue
                                        v-model="mount"
                                        required
                                        type="text" inputmode="decimal"
                                        :readonly="isDisabled"
                                        @input="onChangeMount"
                                        @blur="onBlurMount"
                                        class="autonumeric_input"
                                        :options="json_opciones"
                                        :placeholder="'$0.00'"
                                    ></AutoNumericVue>

                                    <p style="text-align: center; font-size: 14px;">Envio + comisión= {{mountConversion}}<small style="font-weight: bold; font-size:10px"> {{currency}}</small></p>
                                    <p
                                class="textLabel"
                                style="text-align: center;margin-bottom: 10px;"
                            >
                                Ingrese su código PIN (*): <feather-icon
                                                icon="HelpCircleIcon"
                                                size="16"
                                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                                title="Código PIN de seguridad"
                                                style="margin-right: 5px;cursor:pointer;"
                                                /> </p>

                            <PincodeInput
                                v-model="pin"
                                placeholder="-"
                                :length="6"
                                :autofocus="false"
                                :disabled="isDisabled"
                                :secure="true"
                                required
                            />
                            <b-button
                            size="sm"
                                id="btn_transferir"
                                ref="btn_transferir"
                                type="submit"
                                variant="primary"
                                block
                                :disabled="isDisabled"
                               
                            >

                                Enviar remesa

                            </b-button>

</b-col>

<b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top:0px">

  <b-alert
                          variant="primary"
                          show
                          style="margin-top:15px"
                        >
                          <div class="alert-body">
                          <p  style="text-align: left; margin-bottom: 0px;">(*) La tasa actual es: <b>{{currency}} {{rate}}</b></p>
                            <p  style="text-align: left; margin-bottom: 0px;">(*) Se cobrará una comisión de <b>$ {{comision}} USD</b> </p>
                           <p style="text-align: left; margin-bottom: 0px;">(*) El monto mínimo de envio es de <b>${{minRemesa}} USD</b>  </p>
                           <p style="text-align: left; margin-bottom: 0px;">(*) El monto máximo de envio es de <b>${{maxRemesa}} USD</b>  </p>
                          
                          
                          </div>
                        </b-alert>

</b-col>

              </b-row>

              </b-form>


                                




        </div>


        </b-modal>
  
  
  </div>
  
  </template>
  
  <script>
  import {
    VBTooltip,VBPopover, BButton, BRow, BCol, BCard, BForm, BAlert, BFormInput, BAvatar
  } from 'bootstrap-vue'

  import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';


  import PincodeInput from 'vue-pincode-input'

  
  export default {
    components: {
      BButton,
      BAvatar,
      BFormInput,
      BForm,
      BRow,
      BCol,
      BAlert,
      BCard,
      PincodeInput,
      AutoNumericVue
    },
      directives: {
            'b-popover': VBPopover,
      'b-tooltip': VBTooltip,
    },
    props: ['userId','tokenAuth', 'contactId','firstName', 'lastName','numberAccount', 'nameBank', 'country', 'rate','balance','comision','minRemesa','maxRemesa', 'currency'],
    data() {
  
                          
  
      return {
       pin:"",
       isDisabled:false,
       title:"",
       mount:"",
       json_opciones:{},
       mountConversion:"0.00",

      

      
      }
    },
    computed: {
  
  
  
    },
    watch: {


  
  
  
    },
    mounted() {

        this.json_opciones={decimalPlaces:0, currencySymbol: "$",   minimumValue: 0, maximumValue:this.maxRemesa, modifyValueOnWheel: false };

     
      this.title="Envio de remesas a " + this.country;

        this.$refs.modalEnviarRemesas.show();

     
    },
    methods: {

        onChangeMount(cantidad){

      

            if(cantidad == null || cantidad == "null" || this.mountBuy ==""){

                this.mountConversion="0.00";
 

            }else{

            if(Number(cantidad) <= Number("0")){
                this.mountConversion="0.00";
                

            }else{
              

                let  mountRound=Number(Number(cantidad) - Number(this.comision)).toFixed(2);
                let mountRemittance = Number(mountRound) * Number(this.rate);

            
                   this.mountConversion=mountRemittance.toFixed(2);



            }

            }




            },

        onBlurMount(){



if(this.mount == null || this.mount == "null" || this.mount ==""){
  this.mount ="";
  this.mountConversion="0.00";



  }else{

    



      if(Number(this.mount) > Number(this.balance)){
        

            this.mount="";
            this.mountConversion="0.00";

             this.$toast.error("La cantidad a enviar debe ser menor a su saldo disponible de $" +this.balance+" USD" , {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

    


        }else{


          if(Number(this.mount) < Number(this.minRemesa)){

            this.mount="";

              this.$toast.error("La cantidad a enviar debe ser mayor a $" +this.minRemesa+" USD" , {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })


          }


        }

    

 

    


  }

},
    

     closeModalEnviarRemesas(){

     
         
        //   this.$eventBus.$emit('reiniciarAgregarContactosRemesas');
    
        this.$eventBus.$emit('reiniciarSearchContactsHome')
        this.$eventBus.$emit('reiniciarSendRemesas')

    },
        onSubmit(event){
      event.preventDefault()



        
            const userId_json = {
              userId: this.userId, pin: this.pin, contactId: this.contactId, mount: this.mount  
            }
            const user_string = JSON.stringify(userId_json)




            this.isDisabled = true

            const dataEncripted = this.$encryptBackoffice.encrypt(user_string)



            document.getElementById('btn_transferir').innerHTML = '<img style="margin-left:10px; " src="/img/loading2.svg" alt="Loading" />Enviando'

            this.$https.post('/remesas/sendRemesa/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
              this.isDisabled = false
   

              document.getElementById('btn_transferir').innerHTML = "Enviar remesa";

              if (response.data.code == 200) {


                    this.$toast.success(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                });

                this.$refs.modalEnviarRemesas.hide()
  
        
                
                this.$eventBus.$emit('reiniciarEnviar2');
                this.$eventBus.$emit('reiniciarTransaccionesHome');
                this.$eventBus.$emit('reiniciarListadoRemesas')

          




              } else {
           

                this.$toast.error(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

                if (response.data.code == 401) {
                  
                  localStorage.removeItem('userData')


    
                  
                  

                

                  
                  

                  // Redirect to login page
                  this.$router.push({ name: 'auth-login' })
                }else if (response.data.code == 503) {
                  this.pin = ''
                }else if(response.data.code == 501){
                  this.$swal({
                      title: 'Tu perfil debe estar verificado para realizar esta acción',
                      text: `¿Deseas verificar tu cuenta ahora?`,
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Verificar cuenta',
                      cancelButtonText: 'No',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-secondary ml-1',
                      },
                      buttonsStyling: false,
                    }).then(result => {
                      if (result.value) {

                        this.$router.push({ name: 'perfil', hash: '#verificacion' })

                      }
                    })
                }
              }
            }).catch(error => {
              this.$toast.error("Ocurrió un error inesperado", {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
            })



    }


  
     
    }

    
  }
  </script>
  
  <style lang="scss">
  
  
  
  
  
  </style>
  