<template>

    <div>

      <modal-enviar-remesas v-if="openModalEnviarRemesas" :contact-id= "contactId" :currency="currency" :comision="comision" :min-remesa="minRemesa" :max-remesa="maxRemesa" :number-account="numberAccount" :balance="balance" :rate="rate" :country="country"  :first-name="firstName" :last-name="lastName" :name-bank="nameBank"  :user-id="userId" :token-auth="tokenAuth"  />
    

    <b-modal
    scrollable 
      ref="modalSearchContacts"
      centered
      hide-footer
      @hidden="closemodalSearchContacts"
      hideHeaderClose
                hideHeader
      size="sm"
    >
      <div class="d-block text-center">

        <b-row>
          <b-col  sm="12" md="12" lg="12" xl="12"  >

           
              <p style="text-align:left; font-size:14px;margin-top: 0px;margin-bottom:5px">        <feather-icon
                  icon="SearchIcon"
                  size="18"
                  class="color_icon"
                  style="margin-right: 10px;"
                  /><strong>Buscar un contacto</strong></p>

 

              </b-col>

              <b-col  sm="12" md="12" lg="12" xl="12" >

                <div class="search-input" >

                        <!-- <input type="text" placeholder="Explore Vuexy...." class="form-control-input"> -->
                        <!-- @keyup.esc="escPressed" -->
                        <!-- @keyup.enter="suggestionSelected" -->
                        <b-form-input
                        ref="searchInput"
                            v-model="searchQuery"
                            placeholder="Buscar por nombre, celular o email ..."
                            autocomplete="off"
                        />
                   <div class="search-input-close">


                    <div style="cursor:pointer;margin-right: 20px;   display: inline-block !important;"  @click="clear">
                    <feather-icon icon="XIcon" size="18"  class="clear_icon" :class="{'show_clear': searchQuery}"/>
                        
                    </div>


                    </div>

                    </div>

                    </b-col>


                    <b-col :class="{'scroll_div_negocios': minContactos}"   cols="12" sm="12" md="12" lg="12" xl="12">

                        <div v-if="startSearch">

                          <div v-if="items.length != 0">

                            <b-card class="card_opciones"  @click="sendRemesas(item.id, item.firstName, item.lastName, item.nameBank, item.countryCode, item.country, item.numberAccount  )" v-for="item in items" :key="item.id">

                              <b-row>
                                  <b-col cols="3" sm="3" md="2" lg="2" xl="2" align="center" style="display:flex"  >

                                    <div style="margin:auto">

                                      <b-avatar
                                                        size="40"
                                        
                                                        variant="light-primary"
                                                        style="margin:auto"
                                                      >
                                        
                                                      </b-avatar>

                                    </div>






                                      </b-col>

                                      <b-col cols="9" sm="9" md="10" lg="10" xl="10" >

                                        <p class="p1_opciones" style="text-align:left; margin:0px; font-weight: bold;font-size:14px">{{item.firstName}} {{item.lastName}} </p>  
                                        <p class="p1_opciones" style="text-align:left;font-size:12px;margin:0px;font-weight: 400;">{{item.phoneNumber}} </p>  
                                    
                                        <p class="p1_opciones" v-if="item.email != ''" style="text-align:left;font-size:12px; margin:0px; font-weight: 400;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{item.email}} </p>  
                                      </b-col>
                              </b-row>

                              </b-card>

                          </div>

                          


                                <div  v-else align="center">

                               
                              <svg width="100" height="100" viewBox="-20 0 190 190"  xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M38.155 140.475 48.988 62.1108l43.881 4.946 18.568 23.955-8.041 57.1092L38.155 140.475zM84.013 94.0018 88.827 71.8068l-34.781-3.5-9.854 67.1502L98.335 142.084l6.542-45.2752-20.864-2.807zM59.771 123.595C59.394 123.099 56.05 120.299 55.421 119.433 64.32 109.522 86.05 109.645 92.085 122.757 91.08 123.128 86.59 125.072 85.71 125.567 83.192 118.25 68.445 115.942 59.771 123.595zM76.503 96.4988 72.837 99.2588l-5.515-6.642L59.815 96.6468l-3.029-5.069L63.615 88.1508l-4.526-5.452L64.589 79.0188 68.979 85.4578 76.798 81.5328 79.154 86.2638l-7.047 3.783 4.396 6.452z" fill="#4a4a4a"/></svg>
                              <p style="text-align:center;font-size:16px">No se encontraron resultados.</p>
  
                            
                            </div>
                              


                            </div>


                            <div v-else>
                              <p style="text-align: left; font-weight: bold; margin:5px;">Seleccione un contacto:</p>

                              <b-card class="card_opciones" @click="sendRemesas(item.id, item.firstName, item.lastName, item.nameBank, item.countryCode, item.country, item.numberAccount  )" v-for="item in visiblePost" :key="item.id">

                              <b-row>
                                  <b-col cols="3" sm="3" md="2" lg="2" xl="2" align="center" style="display:flex"  >

                                    <div style="margin:auto">

                                      <b-avatar
                                                        size="40"
                                                        
                                                        variant="light-primary"
                                                        style="margin:auto"
                                                      >
                                        
                                                      </b-avatar>

                                    </div>






                                      </b-col>

                                      <b-col cols="9" sm="9" md="10" lg="10" xl="10" >

                                        <p class="p1_opciones" style="text-align:left; margin:0px; font-weight: bold;font-size:14px">{{item.firstName}} {{item.lastName}} </p>  
                                          <p class="p1_opciones" style="text-align:left;font-size:12px;margin:0px;font-weight: 400;">{{item.phoneNumber}} </p>  
                                      
                                          <p class="p1_opciones" v-if="item.email != ''" style="text-align:left;font-size:12px; margin:0px; font-weight: 400;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{item.email}} </p>  
                                      </b-col>
                              </b-row>

                              </b-card>




                                  <div align="center">

                                  
                                
                                    <b-button
                                    v-if="postVisible < contactos.length"
                                                                    
                                                                      variant="primary"
                                                                      style="margin-bottom:15px"
                                                                      size="md"
                                                                      @click="postVisible += step" 
                                                                
                                                                    >
                                                                  Cargar más contactos

                                                                    </b-button>
                                      </div>

                                
                                  </div>


                        </b-col>
        </b-row>

       

        </div>


        </b-modal>
  
  
  </div>
  
  </template>
  
  <script>
  import {
   BButton, BRow, BCol, BCard, BForm, BAlert, BFormInput, BAvatar
  } from 'bootstrap-vue'

  import ModalEnviarRemesas from './ModalEnviarRemesas.vue'


  
  export default {
    components: {
      BButton,
      BFormInput,
      BAvatar,
      BForm,
      
      BRow,
      BCol,
      BAlert,
      BCard,
      ModalEnviarRemesas,
    },
    props: ['userId','tokenAuth', 'contactos'],
    data() {


             
  
      return {
        openModalEnviarRemesas:false,
        reiniciar:true,
        postVisible: 4,
            step: 4,
            startSearch:false,
        searchQuery:"",
        firstName:"", lastName:"", nameBank:"", country:"",balance:0, rate:0, numberAccount:"", currency:"",comision:0,contactId:"", minRemesa:0, maxRemesa:0,
        minContactos:false,


      
      }
    },
    computed: {
  
      visiblePost() {
          return this.contactos.slice(0, this.postVisible)
          },

          items() {
                return this.contactos.filter(item => {
                  return item.firstName.toLowerCase().includes(this.searchQuery.toLowerCase()) || item.lastName.toLowerCase().includes(this.searchQuery.toLowerCase()) || item.phoneNumber.toLowerCase().includes(this.searchQuery.toLowerCase()) || item.email.toLowerCase().includes(this.searchQuery.toLowerCase());
                });
              },
  
    },
    watch: {

      searchQuery(value){

      if(Number(value.length) != Number("0")){
        this.startSearch=true;
      }else{
        this.startSearch=false;
      }

      }
  
  
  
    },
    mounted() {
     
      if(Number(this.contactos.length) > Number("3")){
                      this.minContactos=true;
                    }   

        this.$refs.modalSearchContacts.show();

 
  
    },
    methods: {
    
      clear(){
      
      this.$refs.searchInput.focus();
      this.searchQuery = '';

    },

     closemodalSearchContacts(){

     
         
        //   this.$eventBus.$emit('reiniciarAgregarContactosRemesas');

          if(this.reiniciar){
            this.$eventBus.$emit('reiniciarSearchContactsHome')

          }
    
          

    },sendRemesas(id,firstName, lastName, nameBank, countryCode, country, numberAccount ){

      this.$swal({

          html:
          '<img style="margin-left:10px; width: 30px" src="/img/loading.svg" alt="Loading" /> Comprobando estado',
          showCloseButton: false,
          showCancelButton: false,
          focusConfirm: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          position: 'center',
          })




      const userId_json = {
              userId: this.userId, countryCode: countryCode, contactId:id
            

            }
            const user_string = JSON.stringify(userId_json)


            const dataEncripted = this.$encryptBackoffice.encrypt(user_string)



   
            this.$https.post('/remesas/checkContact/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
       
              this.$swal.close()
              if (response.data.code == 200) {


                this.firstName=firstName
                this.lastName=lastName
                this.nameBank=nameBank;
                this.contactId= id;

                this.numberAccount=numberAccount;
                this.country= country
                this.balance=  response.data.balance;
                this.comision= response.data.comision;
                this.currency= response.data.currency;
                this.rate=  response.data.rate;
                this.minRemesa= response.data.minRemesa;
                this.maxRemesa= response.data.maxRemesa;
               
               
                this.reiniciar=false;

                this.$refs.modalSearchContacts.hide();

                this.openModalEnviarRemesas=true;
  



              } else {
           

                this.$toast.error(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

                if (response.data.code == 401) {
                  
                  localStorage.removeItem('userData')



                  
                  

                

                  
                  

                  // Redirect to login page
                  this.$router.push({ name: 'auth-login' })
                }
              }
            }).catch(error => {
              this.$toast.error("Ocurrió un error inesperado", {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
            })


    }



  
     
    }

    
  }
  </script>
  
  <style lang="scss">
  
  
  
  
  
  </style>
  